import { graphql, useStaticQuery } from 'gatsby';

const recommendationsQuery = graphql`
   query {
      allMarkdownRemark(
         filter: { fileAbsolutePath: { regex: "/(recommendations)/" } }
      ) {
         edges {
            node {
               id
               frontmatter {
                  order
                  avatar
                  name
                  position
                  company
                  logo
                  title
               }
               html
               excerpt(pruneLength: 405)
            }
         }
      }
   }
`;

export const useRecommendations = () => {
   const { allMarkdownRemark } = useStaticQuery(recommendationsQuery);

   const formatted = allMarkdownRemark.edges.map(({ node }) => {
      const { frontmatter, ...rest } = node;
      return { ...frontmatter, ...rest };
   });

   formatted.sort(({ order: a }, { order: b }) => +a - +b);
   return formatted;
};
