import React from 'react';
import {
   About,
   Divider,
   Footer,
   Hero,
   Project,
   Recommendation,
} from '../components';
import { useRecommendations, useProjects } from '../hooks';

export default () => {
   const recommendations = useRecommendations();
   const projects = useProjects();

   return (
      <main className="font-mono">
         {/* Hero */}
         <Hero />

         <Divider className="text-white bg-gray-100" />

         {/* About me */}
         <About />

         <Divider className="text-gray-100 bg-gray-200" />

         {/* Recommendations */}
         <section className="py-12 bg-gray-200">
            <div className="container mx-auto">
               <div className="text-2xl text-center text-gray-700 md:text-4xl">
                  Recommendations
               </div>
               <div className="relative flex flex-wrap justify-center mt-12">
                  {recommendations.map(recommendation => (
                     <Recommendation
                        key={recommendation.id}
                        {...recommendation}
                     />
                  ))}
               </div>
            </div>
         </section>

         <Divider className="text-gray-200 bg-gray-100" />

         {/* Projects */}
         <section className="py-12 bg-gray-100">
            <div className="container mx-auto">
               <div className="text-2xl text-center text-gray-700 md:text-4xl">
                  Open Source Projects
               </div>
               <div className="relative flex flex-wrap justify-center mt-12">
                  {projects
                     .filter(({ active }) => active)
                     .map(project => (
                        <Project key={project.id} {...project} />
                     ))}
               </div>
            </div>
         </section>

         <Divider className="text-gray-100 bg-gray-200" />

         {/* Footer */}
         <Footer className="bg-gray-200" />
      </main>
   );
};
