import React from 'react';

const links = [
   {
      icon: 'linkedin.svg',
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/in/dejansandic',
      height: 'h-6',
   },
   {
      icon: 'github.svg',
      name: 'GitHub',
      link: 'https://github.com/dejansandic',
      height: 'h-8',
   },
   {
      icon: 'npm.svg',
      name: 'NPM',
      link: 'https://www.npmjs.com/~dejansandic',
      height: 'h-8',
   },
   {
      icon: 'stack-overflow.svg',
      name: 'Stack Overflow',
      link: 'https://stackoverflow.com/users/14608966/dejan-sandic',
      height: 'h-6',
   },
];

export const SocialLinks = ({ className = '', names }) => (
   <div className={`flex justify-center items-center ${className}`}>
      {links.map(({ icon, name, link, height }) => (
         <a
            key={link}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:opacity-75 hover:text-blue-700"
         >
            <img src={`/svg/${icon}`} alt={name} className={height} />
            {names && <span className="hidden md:inline">{name}</span>}
         </a>
      ))}
   </div>
);
