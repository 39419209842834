import React from 'react';

export const About = () => (
   <section className="px-6 py-10 text-gray-700 bg-gray-100">
      <div className="max-w-4xl mx-auto mt-6 space-y-3 text-sm text-justify md:text-base">
         <p>
            My greatest passion is implementing processes and creating tools and
            utilities that help my teammates do their job better and easier.
         </p>
         <p>
            I love learning from my teammates as well as sharing my knowledge
            and experience with them. I enjoy contributing to a project and
            watching it evolve along with the team behind it.{' '}
         </p>
      </div>
   </section>
);
