import React from 'react';
import { Link } from 'gatsby';

export const Recommendation = props => {
   const { avatar, name, position, company, logo, title } = props;
   const { order, full, excerpt, html } = props;

   const content = (
      <div className="space-y-6" dangerouslySetInnerHTML={{ __html: html }} />
   );

   return (
      <div
         className={`relative p-6 mx-4 my-6 space-y-4 bg-white rounded ${
            full ? 'max-w-2xl' : 'max-w-sm'
         }`}
      >
         <div
            id={`rec-${order}`}
            className="absolute top-0 right-0 w-10 h-12 mr-10 -mt-4 text-center bg-blue-400"
         >
            <span className="text-5xl text-white">”</span>
         </div>

         <div className="flex items-center space-x-4 text-xs">
            <div
               className="flex-shrink-0 w-20 h-20 bg-center bg-no-repeat bg-cover rounded-full"
               style={{ backgroundImage: `url(/img/clients/${avatar})` }}
            />
            <div className="flex flex-col justify-center flex-grow">
               <div className="text-sm font-extrabold">{title}</div>
            </div>
         </div>

         <hr />

         <div className="font-sans text-sm">
            <div className="text-justify">
               {full ? (
                  content
               ) : (
                  <Link to={`/recommendations#rec-${order}`}>
                     <p className="leading-relaxed">{excerpt}</p>
                  </Link>
               )}
            </div>
            <div className="flex justify-between pt-4 space-x-2 font-bold text-gray-600">
               {!full && (
                  <Link to={`/recommendations#rec-${order}`}>
                     <span className="cursor-pointer hover:text-gray-500">
                        Read more...
                     </span>
                  </Link>
               )}

               <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/dejansandic/"
                  className="cursor-pointer hover:text-gray-500"
               >
                  Read on LinkedIn
               </a>
            </div>
         </div>

         <hr />

         <div className="flex space-x-4 text-sm">
            <div
               className="flex-shrink-0 w-12 h-12 bg-center bg-no-repeat bg-cover rounded-full"
               style={{ backgroundImage: `url(/img/companies/${logo})` }}
            />
            <div className="flex flex-col justify-center flex-grow">
               <div className="font-bold">{name}</div>
               <div className="italic">
                  {`${position} `}{' '}
                  <span className="font-bold text-blue-500">{company}</span>
               </div>
            </div>
         </div>
      </div>
   );
};
