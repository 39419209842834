import React from 'react';
import { SocialLinks } from './SocialLinks';

export const Hero = () => (
   <section>
      <div className="container pt-32 pb-16 mx-auto">
         <div className="px-6 text-xl text-center md:text-2xl">
            <h2 className="italic font-bold text-gray-700">Dejan Sandic</h2>
            <h1 className="font-black text-blue-500 text-7xl">Deyo</h1>

            <p className="leading-8 text-gray-700 md:leading-10">
               <span className="font-bold">{'JavaScript'}</span>
               <span>{' developer with more than '}</span>
               <span className="font-bold">{'7 years'}</span>
               <span>{' of experience'}</span>
            </p>
         </div>

         <SocialLinks
            className="pt-12 space-x-6 font-bold text-gray-700"
            names
         />
      </div>
   </section>
);
