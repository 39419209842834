export default {
	typescript: {
		name: 'TypeScript',
		icon: '/svg/typescript.svg',
		color: '#1e7acc'
	},
	react: {
		name: 'React',
		icon: '/svg/react.svg',
		color: '#5ed4f4'
	},
	electron: {
		name: 'Electron',
		icon: '/svg/electron.svg',
		color: '#5ed4f4'
	},
	'vs-code': {
		name: 'Visual Studio Code',
		icon: '/svg/vs-code.svg',
		color: '#3a95ce'
	}
};
