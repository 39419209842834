import React from 'react';
import { useTechnologies } from '../hooks';

export const Project = ({ id, name, type, description, npm, github }) => {
   const technologies = useTechnologies();
   const { icon, name: technologyName, color } = technologies[type] || {};

   return (
      <div
         className="flex flex-col items-start w-full p-4 m-4 bg-white rounded shadow-md sm:w-1/2 md:w-1/3 lg:w-1/4 min-h-40"
         style={{ borderLeft: `4px solid ${color}` }}
         key={id}
      >
         {/* Technology icon */}
         <img
            className="h-20 mb-6"
            src={icon}
            alt={technologyName}
            title={technologyName}
         />

         {/* Project name */}
         <p className="pt-4 text-lg font-semibold text-gray-800 hover:text-gray-500 hover:underline">
            <a href={npm || github} title="NPM" target="blank">
               {name}
            </a>
         </p>

         {/* Project description */}
         <p className="flex-grow py-2 text-sm text-gray-600">{description}</p>

         <div className="flex items-end justify-between w-full pt-2 mt-2 border-t">
            {/* Technology icon */}
            <img
               className="h-6"
               src={icon}
               alt={technologyName}
               title={technologyName}
            />

            {/* Repository icons */}
            <div className="flex">
               {npm && (
                  <a href={npm} title="NPM" target="blank">
                     <img className="h-6" src="/svg/npm.svg" alt="NPM" />
                  </a>
               )}
               <a href={github} title="Github" target="blank">
                  <img className="h-6" src="/svg/github.svg" alt="Github" />
               </a>
            </div>
         </div>
      </div>
   );
};
