import React from 'react';
import { SocialLinks } from './SocialLinks';

export const Footer = ({ className = '' }) => (
   <footer className={`py-10 ${className}`}>
      <div className="container mx-auto space-y-6 font-semibold text-gray-700">
         <SocialLinks className="space-x-4" />

         <div className="text-sm text-center">
            <span className="text-xl font-extrabold">{'© '}</span>
            <span className="italic">{new Date().getFullYear()}</span>
            <span>{' | '}</span>
            <span className="text-lg font-extrabold text-blue-500">{`Deyo `}</span>
            <span className="italic">- Dejan Sandic</span>
         </div>
      </div>
   </footer>
);
