export default [
   {
      id: 1,
      active: true,
      name: 'clean-react-directives',
      type: 'react',
      description: 'Vue-like syntax for ReactJS',
      github: 'https://github.com/DejanSandic/clean-react-directives',
      npm: 'https://www.npmjs.com/package/clean-react-directives',
   },
   {
      id: 2,
      active: true,
      name: 'use-emitter',
      type: 'react',
      description: 'React helper for event emitters and socket.io',
      github: 'https://github.com/DejanSandic/use-emitter',
      npm: 'https://www.npmjs.com/package/use-emitter',
   },
   {
      id: 9,
      active: true,
      name: 'react-calendar-hook',
      type: 'react',
      description:
         'React hook which enables you to easily create your custom calendars in couple of minutes',
      github: 'https://github.com/DejanSandic/react-calendar-hook',
      npm: 'https://www.npmjs.com/package/react-calendar-hook',
   },
   {
      id: 3,
      active: true,
      name: 'json-blueprint',
      type: 'typescript',
      description: 'JSON validation library',
      github: 'https://github.com/DejanSandic/json-blueprint',
      npm: 'https://www.npmjs.com/package/json-blueprint',
   },
   {
      id: 4,
      active: false,
      name: 'event-jet',
      type: 'typescript',
      description: 'Turbocharged event emitter',
      github: 'https://github.com/DejanSandic/event-jet',
      npm: 'https://www.npmjs.com/package/event-jet',
   },
   {
      id: 5,
      active: true,
      name: 'apply-middleware',
      type: 'typescript',
      description: 'Apply any number of middleware functions to any function',
      github: 'https://github.com/DejanSandic/apply-middleware',
      npm: 'https://www.npmjs.com/package/apply-middleware',
   },
   {
      id: 6,
      active: false,
      name: 'static-forge',
      type: 'vs-code',
      description: 'Static forge for Visual Studio Code',
      github: 'https://github.com/DejanSandic/static-forge',
   },
   {
      id: 9,
      active: false,
      name: 'md-player',
      type: 'electron',
      description: 'Desktop Movie Player',
      github: 'https://github.com/DejanSandic/md-player',
   },
   {
      id: 7,
      active: false,
      name: 'ds-library-starter',
      type: 'typescript',
      description:
         'Boilerplate for creating JavaScript libraries with TypeScript',
      github: 'https://github.com/DejanSandic/ds-library-starter',
   },
   {
      id: 8,
      active: false,
      name: 'ds-react-library-starter',
      type: 'react',
      description: 'Boilerplate for creating React libraries with TypeScript',
      github: 'https://github.com/DejanSandic/ds-react-library-starter',
   },
]
